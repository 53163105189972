import React from "react";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { socialMediaStyles } from "./SocialMedia.styles";
import { getSocialMediaTxtSelector } from "../../state";
import EmailIcon from "../../images/Email.svg";

export function _Email({ large, orgName, socialTxt }) {
  const classes = socialMediaStyles();

  return (
    <IconButton
      href={`mailto:?subject=${orgName}&body=${socialTxt}`}
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={EmailIcon}
        alt="email"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Email = connect(state => {
  return {
    orgName: state.campaign.name,
    socialTxt: getSocialMediaTxtSelector(state, true, true),
  };
})(_Email);
