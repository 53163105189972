import React from "react";
import { useMediaQuery, Container, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Facebook,
  Twitter,
  Linkedin,
  Whatsapp,
  IMessage,
  Email,
} from "../components";
import { footerStyles } from "./Footer.styles";
import { bindActionCreators } from "redux";
import {
  campaignActions,
  getUrls,
  submissionActions,
  uiActions,
} from "../state";
import { getEncodedCurrentPathWithQuery, isBigKahunaCompany } from "../lib";

function _Footer(props) {
  const {
    companyId,
    company_name,
    company_logo,
    company_website,
    loginUrl,
    homeUrl,
    registerUrl,
    donationSuccess,
    isVolunteer,
    no_volunteer_registration,
    actions: { openSubmissionDialog, updateHashLinkTrigger, clearCampaignData },
  } = props;
  const history = useHistory();
  const classes = footerStyles();
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isBigKahuna = isBigKahunaCompany(companyId);

  const ContactCompany = () => (
    <div
      className={classes.contactCompany}
      onClick={() => openSubmissionDialog("company")}
    >
      Contact us
    </div>
  );

  const PoweredBy = () => (
    <div>&copy; {`Powered by ${company_name || ""}`}</div>
  );

  const goToPrivacyPage = () => {
    const redirect = getEncodedCurrentPathWithQuery();
    clearCampaignData();
    history.push(`/privacy?redirect=${redirect}`);
  };

  const onClick = (updateHLTrigger = false) => {
    if (updateHLTrigger) updateHashLinkTrigger();
    if (donationSuccess) {
      setTimeout(() => window.location.reload(), 100);
    }
  };

  return (
    <div className={classes.container}>
      {smallDevice && (
        <div className={classes.mobileContent}>
          <div className={classes.socialLinks}>
            <Facebook /> <Twitter /> <Linkedin /> <Whatsapp />
            <Email /> <IMessage />
          </div>

          <div className={classes.mobileButtons}>
            <Button
              size="small"
              className={classes.mobileButton}
              component={Link}
              to={`${homeUrl}#top`}
              onClick={() => onClick(true)}
            >
              Home
            </Button>
            {!isVolunteer && !no_volunteer_registration && (
              <Button
                size="small"
                className={classes.mobileButton}
                component={Link}
                to={registerUrl}
                onClick={onClick}
              >
                Register
              </Button>
            )}
            {!isBigKahuna && (
              <Button
                size="small"
                className={classes.mobileButton}
                onClick={() => openSubmissionDialog("org")}
              >
                Contact
              </Button>
            )}
            <Button
              size="small"
              className={classes.mobileButton}
              href={loginUrl}
              onClick={onClick}
            >
              Login
            </Button>
            <Button
              size="small"
              className={classes.mobileButton}
              onClick={goToPrivacyPage}
            >
              Privacy
            </Button>
          </div>

          {company_logo && (
            <a href={company_website} target="_blank" rel="noopener noreferrer">
              <img
                className={classes.logo}
                src={company_logo}
                alt="powered by"
              />
            </a>
          )}
        </div>
      )}

      <div className={classes.bottomBar}>
        <Container>
          <div className={classes.bottomBarContent}>
            {!smallDevice && company_logo && (
              <a
                href={company_website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.logo}
                  src={company_logo}
                  alt="powered by"
                />
              </a>
            )}
            {!smallDevice && (
              <div className={classes.bottomLinks}>
                <ContactCompany />
                <div className={classes.legal} onClick={goToPrivacyPage}>
                  Privacy
                </div>
                <PoweredBy />
              </div>
            )}
            {smallDevice && (
              <div className={classes.bottomLinks}>
                <PoweredBy />
                <ContactCompany />
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}

export const Footer = connect(
  state => {
    const {
      campaign: {
        companyId,
        company_name,
        company_logo,
        company_website,
        volunteerSlug,
        no_volunteer_registration,
      },
      donation: { donationSuccess },
    } = state;
    const { loginUrl, homeUrl, registerUrl } = getUrls(state);
    return {
      companyId,
      company_name,
      company_logo,
      company_website,
      loginUrl,
      homeUrl,
      registerUrl,
      donationSuccess,
      isVolunteer: Boolean(volunteerSlug),
      no_volunteer_registration,
    };
  },
  dispatch => {
    return {
      actions: {
        ...bindActionCreators(submissionActions, dispatch),
        ...bindActionCreators(uiActions, dispatch),
        ...bindActionCreators(campaignActions, dispatch),
      },
    };
  },
)(_Footer);
