import React, { Fragment, useState, useEffect } from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelRounded";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import queryString from "query-string";
import { videoModalStyles } from "./VideoModel.styles";

export function VideoModal({
  videoLink = "",
  smallIcon,
  parentShowTrigger = false,
}) {
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = videoModalStyles({ smallIcon });
  const [showVideo, toggleVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    let url = null;
    if (videoLink.includes("youtube.com/embed")) {
      url = `${videoLink}?rel=0&autoplay=1`;
    } else if (videoLink.includes("youtube.com")) {
      const youtubeQueryId = queryString.parseUrl(videoLink).query.v;
      if (youtubeQueryId) {
        url = `https://www.youtube.com/embed/${youtubeQueryId}?rel=0&autoplay=1`;
      }
    } else if (videoLink.includes("youtu.be")) {
      const youtubeId = videoLink.substring(videoLink.lastIndexOf("/") + 1);
      url = `https://www.youtube.com/embed/${youtubeId}?rel=0&autoplay=1`;
    } else if (videoLink.includes("vimeo.com/event")) {
      url = `${videoLink}/embed?autoplay=1`;
    } else if (videoLink.includes("vimeo.com")) {
      const vimeoId = videoLink.substring(videoLink.lastIndexOf("/") + 1);
      if (vimeoId) url = `https://player.vimeo.com/video/${vimeoId}?autoplay=1`;
    }
    setVideoUrl(url);
  }, [videoLink]);

  useEffect(() => {
    if (parentShowTrigger) toggleVideo(true);
  }, [parentShowTrigger]);

  if (!videoUrl) return <></>;
  return (
    <Fragment>
      <PlayIcon
        className={classes.playIcon}
        onClick={() => toggleVideo(true)}
      />
      <Dialog
        onClose={() => toggleVideo(false)}
        open={showVideo}
        maxWidth={false}
        classes={{
          paper: classes.dialogPaper,
        }}
        fullScreen={isSmallDevice}
      >
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <iframe
              className={classes.iframe}
              allowFullScreen
              width={560}
              height={315}
              title="video"
              src={videoUrl}
              autoPlay
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
            <CancelIcon
              className={classes.close}
              onClick={() => toggleVideo(false)}
            />
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
