import React, { useEffect, Fragment } from "react";
import {
  Button,
  makeStyles,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Field, useForm } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { connect } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import { donationFormStyles } from "./DonationForm.styles";

function _GiftCardInfo({ is_gift_card_site }) {
  const DFClasses = donationFormStyles({ isProduct: true });
  const classes = styles();
  const form = useForm();

  useEffect(() => {
    if (is_gift_card_site) {
      setTimeout(() => {
        form.change("giftCards", [{ gift_code: "" }]);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_gift_card_site]);

  if (!is_gift_card_site) return <Fragment />;
  return (
    <div className={classes.container}>
      <div className={DFClasses.formSubHeader}>{`Enter gift card(s)`}</div>

      <FieldArray name={"giftCards"}>
        {({ fields }) => (
          <Fragment>
            {fields.map((row, index) => {
              return (
                <div key={index}>
                  <Field
                    name={`${row}.gift_code`}
                    component={TextField}
                    label="Gift code"
                    className={DFClasses.input}
                    InputProps={{
                      endAdornment:
                        index > 0 ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => fields.remove(index)}
                              size="small"
                            >
                              <RemoveIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                </div>
              );
            })}

            <div className={classes.button}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => fields.push({ gift_code: "" })}
                startIcon={<AddIcon />}
              >
                Add another gift code
              </Button>
            </div>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
}

export const GiftCardInfo = connect(state => {
  return { is_gift_card_site: state.campaign.is_gift_card_site };
})(_GiftCardInfo);

const styles = makeStyles(_theme => ({
  container: {
    marginBottom: "48px",
  },
  button: {
    marginTop: 16,
  },
}));
