import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link as RRLink } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  makeStyles,
  Container,
} from "@material-ui/core";
import { getUrls, uiActions } from "../../state";

function _Breadcrumbs(props) {
  const {
    forVolunteer,
    orgName,
    teamSlug,
    team_name,
    first_name,
    last_name,
    orgUrl,
    teamUrl,
    volunteerUrl,
  } = props;
  const classes = styles();
  const dispatch = useDispatch();

  const onLinkClick = () => {
    dispatch(uiActions.updateHashLinkTrigger());
  };

  return (
    <Container>
      <div className={classes.container}>
        <MuiBreadcrumbs className={classes.breadCrumbs}>
          <Link
            className={classes.link}
            color="inherit"
            component={RRLink}
            to={`${orgUrl}#top`}
            onClick={onLinkClick}
          >
            {orgName}
          </Link>
          {teamSlug && (
            <Link
              className={classes.link}
              color={forVolunteer ? "inherit" : "secondary"}
              component={RRLink}
              to={`${teamUrl}#top`}
              onClick={onLinkClick}
            >
              {team_name}
            </Link>
          )}
          {forVolunteer && (
            <Link
              className={classes.link}
              color="primary"
              component={RRLink}
              to={`${volunteerUrl}#top`}
              onClick={onLinkClick}
            >
              {`${first_name} ${last_name}`}
            </Link>
          )}
        </MuiBreadcrumbs>
      </div>
    </Container>
  );
}

export const Breadcrumbs = connect(state => {
  const {
    campaign: {
      name: orgName,
      team_slug: teamSlug,
      team_name,
      first_name,
      last_name,
    },
  } = state;
  const { orgUrl, teamUrl, volunteerUrl } = getUrls(state);
  return {
    orgName,
    teamSlug,
    team_name,
    first_name,
    last_name,
    orgUrl,
    teamUrl,
    volunteerUrl,
  };
})(_Breadcrumbs);

const styles = makeStyles(theme => ({
  container: {
    marginTop: 24,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  breadCrumbs: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    display: "flex",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  link: {
    textDecoration: "underline",
  },
}));
