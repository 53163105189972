import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { OnChange } from "react-final-form-listeners";
import { donationActions } from "../../state";
const observerFields = [
  "amount",
  "customAmount",
  "ticket_package_id",
  "tip",
  "processing_fee",
  "recurringTotal",
  "installments",
];

const Observer = ({ fieldName, formValues, ticketPkgs }) => {
  const dispatch = useDispatch();
  return (
    <OnChange name={fieldName}>
      {() => {
        const {
          amount,
          customAmount,
          ticket_package_id: selectedPkgId,
          recurringTotal,
          tip,
          processing_fee,
        } = formValues;
        const ticket = Array.isArray(ticketPkgs)
          ? ticketPkgs.find(p => p.id === selectedPkgId)
          : null;

        let baseDonation = ticket
          ? Number(ticket.price)
          : Number(amount || 0) + Number(customAmount || 0);
        baseDonation = Number(baseDonation.toFixed(2));

        const donationAmount = recurringTotal
          ? Number(recurringTotal)
          : baseDonation;

        let donationTotal =
          donationAmount + Number(tip || 0) + Number(processing_fee || 0);
        donationTotal = Number(donationTotal.toFixed(2));

        dispatch(
          donationActions.setDonationTotals({
            baseDonation,
            donationAmount,
            donationTotal,
          }),
        );
        dispatch(donationActions.setGrandTotal(donationTotal.toFixed(2)));
      }}
    </OnChange>
  );
};

function _DonationChangeObserver({ formValues, ticketPkgs }) {
  return (
    <Fragment>
      {observerFields.map(of => (
        <Observer
          key={of}
          fieldName={of}
          formValues={formValues}
          ticketPkgs={ticketPkgs}
        />
      ))}
    </Fragment>
  );
}

export const DonationChangeObserver = connect(state => {
  return { ticketPkgs: state.campaign.campaign_ticket_packages };
})(_DonationChangeObserver);
