import { PO_BOX_REGEX, campaignTypeIds } from "./helpers";
import { setToast } from "../state";

function convertGiftCardObjArrToCodeStrArr(giftCards) {
  const codes = [];
  if (!Array.isArray(giftCards) || !giftCards.length) return codes;
  giftCards.forEach(({ gift_code }) => {
    if (gift_code) codes.push(`${gift_code}`.trim());
  });
  return codes;
}

function customFormValidation(
  campaignInfo,
  values,
  selectedProducts,
  dispatch,
  no_po_boxes,
) {
  const { campaign_type_id, is_gift_card_site } = campaignInfo;
  const {
    amount,
    ticket_package_id,
    customAmount,
    shipping_same_as_billing,
    address,
    shipping_address,
    ship_to_school,
    hasInstallments,
    installments,
    dinner_reservation_gender1,
    dinner_reservation_title1,
    dinner_reservation_name1,
    dinner_reservation_gender2,
    dinner_reservation_title2,
    dinner_reservation_name2,
  } = values;

  const donationAmount = Number(amount || 0) + Number(customAmount || 0);
  const useAddress = shipping_same_as_billing ? address : shipping_address;
  const hasSelectedProducts = Boolean(
    Object.keys(selectedProducts ? selectedProducts : {}).length,
  );

  if (donationAmount && donationAmount < 5) {
    dispatch(setToast("$5 donation minimum!"));
    return false;
  }

  switch (campaign_type_id) {
    case campaignTypeIds.RAFFLE:
      if (!amount && !ticket_package_id) {
        dispatch(
          setToast("Please select a ticket package or enter a custom amount"),
        );
        return false;
      }
      break;
    case campaignTypeIds.DONATION:
      if (!amount && !customAmount) {
        dispatch(setToast("Please select/enter Donation amount"));
        return false;
      }
      if (hasInstallments && !installments) {
        dispatch(setToast("Please select a payment plan"));
        return false;
      }
      if (
        (dinner_reservation_gender1 &&
          (!dinner_reservation_name1 || !dinner_reservation_title1)) ||
        (dinner_reservation_name1 &&
          (!dinner_reservation_gender1 || !dinner_reservation_title1)) ||
        (dinner_reservation_gender2 &&
          (!dinner_reservation_name2 || !dinner_reservation_title2)) ||
        (dinner_reservation_name2 &&
          (!dinner_reservation_gender2 || !dinner_reservation_title2))
      ) {
        dispatch(
          setToast(
            "Your reservation(s) is incomplete. Please complete all fields for your reservation(s) OR leave reservation(s) blank.",
          ),
        );
        return false;
      }
      break;
    case campaignTypeIds.MATCH:
      if (!amount && !customAmount) {
        dispatch(setToast("Please select/enter Donation amount"));
        return false;
      }
      if (hasInstallments && !installments) {
        dispatch(setToast("Please select a payment plan"));
        return false;
      }
      break;
    case campaignTypeIds.PRODUCT:
      if (!hasSelectedProducts && !amount && !customAmount) {
        dispatch(setToast("Your cart is empty."));
        return false;
      }
      if (is_gift_card_site) {
        const invalidGiftCardMsg = validateGiftCards(values);
        if (invalidGiftCardMsg) {
          dispatch(setToast(invalidGiftCardMsg));
          return false;
        }
      }
      if (no_po_boxes && hasSelectedProducts && !ship_to_school) {
        if (PO_BOX_REGEX.test(useAddress)) {
          dispatch(
            setToast("Ship to home is not available to PO box addresses"),
          );
          return false;
        }
      }
      break;
    default:
  }
  return true;
}

function validateGiftCards(formValues) {
  const codes = convertGiftCardObjArrToCodeStrArr(formValues.giftCards);
  if (!codes.length) return;
  const hasDupes = new Set(codes).size !== codes.length;
  return hasDupes ? "You entered duplicate Gift Cards!" : null;
}

export { convertGiftCardObjArrToCodeStrArr, customFormValidation };
