import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const customBreakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1172, // custom container is 1140 + 32px padding (default = 1280)
  xl: 1920,
};
const breakpoints = createBreakpoints({ values: customBreakpoints });

export const theme = {
  palette: {
    text: {
      primary: "#222222",
      secondary: "rgba(34, 34, 34, .31)",
      fontFamily: [
        "Graphik Web",
        "sans-serif",
        "Lato",
        "Roboto",
        "Helvetica",
        "Arial",
      ].join(","),
    },
    primary: {
      main: "#F5F5F5",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F5F5F5",
      contrastText: "#ffffff",
    },
    background: {
      light: "#F5F5F5",
    },
  },
  typography: {
    fontFamily: [
      "Graphik Web",
      "sans-serif",
      "Lato",
      "Roboto",
      "Helvetica",
      "Arial",
    ].join(","),
    background: {
      default: "#ffffff",
    },
  },
  breakpoints: { values: customBreakpoints },
  overrides: {
    MuiContainer: {
      root: {
        width: "1140px",
        maxWidth: "100%",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        [breakpoints.down("md")]: {
          width: "100%",
          maxWidth: "100%",
          minWidth: "100%",
        },
        // [breakpoints.only("md")]: {
        //   padding: "0 !important",
        // },
      },
    },
    MuiInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        [`& fieldset`]: {
          borderRadius: 4,
          border: "1px solid rgba(34, 34, 34, .31)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "rgba(34, 34, 34, .31)",
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0,
        "&$focused": {
          color: "rgba(34, 34, 34, .31)",
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: 0,
        },
        "&$error": {
          color: "#f44336",
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "rgba(34, 34, 34, .31)",
          borderWidth: "1px",
        },
      },
      focused: {},
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "unset",
        borderRadius: 20,
        "&:hover": {
          opacity: 0.8,
        },
      },
      label: {
        fontSize: "unset",
        [breakpoints.down("sm")]: {
          fontSize: "unset",
        },
      },
      contained: {
        backgroundColor: "rgba(34, 34, 34, .31)",
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 4,
        lineHeight: 1.2,
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "rgba(34, 34, 34, .87)",
      },
    },
    MuiCheckbox: {
      root: {
        color: " rgba(34, 34, 34, .65)",
        fontWeight: "normal",
      },
    },
    MuiRadio: {
      root: {
        color: "#222222",
      },
    },
    MuiTabs: {
      // root: {
      //   justifyContent: "center",
      // },
      // scroller: {
      //   flexGrow: "0",
      // },
      // flexContainer: {
      //   [breakpoints.up("sm")]: {
      //     justifyContent: "center",
      //   },
      // },
    },
    MuiTab: {
      root: {
        color: "#222222",
      },
      textColorPrimary: {
        color: "#222222",
      },
    },
    MuiPaper: {
      rounded: { borderRadius: 8 },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiPaper: {
      // square: true,
    },
    MuiIconButton: {
      size: "small",
    },
    MuiContainer: {
      maxWidth: false,
    },
    MuiButton: {
      size: "medium",
    },
  },
};
