import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Button,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  SwipeableDrawer,
} from "@material-ui/core";
import parse from "html-react-parser";
import _isEqual from "lodash.isequal";
import _findIndex from "lodash.findindex";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DecrementIcon from "@material-ui/icons/Remove";
import RemoveIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import InfoIcon from "@material-ui/icons/Info";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { productActions } from "../../state";
import {
  configurableProductOptionDisplayTypes,
  hexToRGBA,
  productStructureTypes,
} from "../../lib";
import { productDetailStyles } from "./ProductDetails.styles";
import { SwitchDeliveryWarningDialog } from "./SwitchDeliveryWarningDialog";
import { PreviewDiscountCard } from "./PreviewDiscountCard";
import { ItemUnavailableWithShippingMethodModal } from "./ItemUnavailableWithShippingMethodModal";

const _ProductDetails = props => {
  const {
    open,
    handleClose,
    hasMultiShipOpts,
    selectedProducts,
    variantProducts,
    isShipToSchool,
    ship_to_school_label,
    product = {},
    product: {
      structure_type,
      configurable_options,
      id: mainProductId,
      product_name,
      category_name,
      product_price: mainPrice,
      image: mainImage,
      product_description: mainDescription,
      additionalImages: mainAdditionalImages,
      ship_to_home_only: STH,
      ship_to_school_only: STS,
      can_personalize,
      personalize_max_chars,
      is_discount_card,
      discount_card_id,
    } = {},
    actions: {
      addProduct,
      removeProduct,
      changeQuantity,
      updatePersonalization,
    },
  } = props;
  const isConfProduct = structure_type === productStructureTypes.CONFIGURABLE;
  const maxChars = personalize_max_chars ? personalize_max_chars : 30;

  const classes = productDetailStyles();
  const [disable, setDisable] = useState(false);
  const [showDeliverySwitch, setShowDeliverySwitch] = useState(false);
  const [variants, setVariants] = useState([]);
  const [availableCombos, setAvailableCombos] = useState([]);
  const [selectedCombo, setSelectedCombo] = useState({});
  const [activeProductId, setActiveProductId] = useState(
    isConfProduct ? null : mainProductId,
  );
  const [activeProduct, setActiveProduct] = useState(
    isConfProduct ? null : product,
  );
  const [displayPrice, setDisplayPrice] = useState(mainPrice);
  const [displayDescription, setDisplayDescription] = useState(mainDescription);
  const [displayImage, setDisplayImage] = useState(mainImage);
  const [displayAdditionalImages, setDisplayAdditionalImages] = useState(
    mainAdditionalImages,
  );
  const [activeImage, setActiveImage] = useState(mainImage);
  const [showDiscountCard, setShowDiscountCard] = useState(false);
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const selectedProduct = selectedProducts[activeProductId];
  const personalization = selectedProduct
    ? selectedProduct.personalization
    : null;
  const isSelected = Boolean(selectedProduct);
  const quantity = isSelected ? selectedProducts[activeProductId].qty : null;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const tooltipTxt = disable
    ? `This item cannot be added to your cart because you selected shipping method 'Ship to ${
        isShipToSchool ? ship_to_school_label : "home"
      }'.`
    : `This item is only available for 'Ship to ${
        isShipToSchool ? ship_to_school_label : "home"
      }' delivery.`;

  useEffect(() => {
    if (
      hasMultiShipOpts &&
      ((isShipToSchool && STH) || (!isShipToSchool && STS))
    ) {
      setDisable(true);
      setShowUnavailableModal(true);
    } else {
      setDisable(false);
      setShowUnavailableModal(false);
    }
  }, [hasMultiShipOpts, isShipToSchool, STS, STH]);

  useEffect(() => {
    if (!isConfProduct) return;
    if (variantProducts[mainProductId]) {
      setVariants(variantProducts[mainProductId]);
      setAvailableCombos(
        variantProducts[mainProductId].map(({ variant }) => variant),
      );
    }
  }, [isConfProduct, mainProductId, variantProducts]);

  useEffect(() => {
    if (!isConfProduct) return;
    const _activeProduct = variants.find(({ variant }) =>
      _isEqual(variant, selectedCombo),
    );
    if (_activeProduct) {
      setActiveProduct(_activeProduct);
      const {
        id,
        product_description,
        product_price,
        image,
        additionalImages,
      } = _activeProduct;
      setActiveProductId(id);
      setDisplayPrice(product_price);
      if (product_description) setDisplayDescription(product_description);
      setDisplayImage(image);
      setActiveImage(image);
      if (additionalImages.length) setDisplayAdditionalImages(additionalImages);
    } else {
      setActiveProduct(null);
    }
  }, [isConfProduct, selectedCombo, variants]);

  const handleAddRemoveProduct = () => {
    if (disable) return;
    if (isSelected) removeProduct(activeProductId);
    else addProduct(activeProductId, activeProduct);
  };

  const handleQtyChange = (id, action) => changeQuantity(id, action);

  const closeDeliverySwitchWarning = () => setShowDeliverySwitch(false);

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => handleClose(false)}
      onOpen={() => handleClose(true)}
      anchor="bottom"
      classes={{ paper: classes.drawer }}
      transitionDuration={isMobile ? 400 : 1}
    >
      <div className={classes.wrapper}>
        <div className={classes.container}>
          {!isMobile && (
            <IconButton
              className={classes.closeButton}
              onClick={() => handleClose(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
          <div className={classes.mainContent}>
            <div className={classes.imageContainer}>
              <div>
                <img
                  className={classes.image}
                  src={activeImage}
                  alt="product"
                />
                {Array.isArray(displayAdditionalImages) &&
                  displayAdditionalImages.length > 0 && (
                    <div className={classes.thumbnails}>
                      <ThumbnailCard
                        image={displayImage}
                        activeImage={activeImage}
                        setActiveImage={setActiveImage}
                      />
                      {displayAdditionalImages.map((image, index) => {
                        return (
                          <ThumbnailCard
                            key={index}
                            image={image}
                            activeImage={activeImage}
                            setActiveImage={setActiveImage}
                            lastCard={
                              index === displayAdditionalImages.length - 1
                            }
                          />
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>

            <div className={classes.rightSection}>
              <div className={classes.productName}>{product_name}</div>
              <div className={classes.category}>
                {/* if it's from a suggested product there wont be a category */}
                {category_name && `In ${category_name}`}
              </div>
              <div className={classes.price}>${displayPrice}</div>

              {Array.isArray(configurable_options) &&
                configurable_options.length > 0 &&
                configurable_options.map((o, index) => {
                  const {
                    option_id,
                    display_type,
                    option_label,
                    option_items,
                  } = o;
                  const textSwatch =
                    display_type ===
                    configurableProductOptionDisplayTypes.TEXT_SWATCH;
                  const colorSwatch =
                    display_type ===
                    configurableProductOptionDisplayTypes.COLOR_SWATCH;
                  const dropdown = !textSwatch && !colorSwatch;
                  const selectedItem =
                    option_items[`_${selectedCombo[option_id]}`];
                  const activeTxt = selectedItem
                    ? selectedItem.display_text
                    : "";
                  return (
                    <div key={index} className={classes.optionGroup}>
                      <div className={classes.optionLabel}>
                        {option_label}
                        <span className={classes.displayText}>{activeTxt}</span>
                      </div>
                      {!dropdown && (
                        <SwatchOptionItems
                          {...{
                            option_id,
                            option_items,
                            selectedCombo,
                            availableCombos,
                            setSelectedCombo,
                            colorSwatch,
                          }}
                        />
                      )}
                      {dropdown && (
                        <DropdownOptionItems
                          {...{
                            option_id,
                            option_items,
                            selectedCombo,
                            availableCombos,
                            setSelectedCombo,
                          }}
                        />
                      )}
                    </div>
                  );
                })}

              {can_personalize && Array.isArray(personalization) && (
                <div className={classes.pnContainer}>
                  <div className={classes.pnText}>
                    Personalization (optional)
                  </div>
                  {personalization.map((text, index) => (
                    <TextField
                      key={index}
                      value={text}
                      label="Text"
                      size="small"
                      className={classes.pnInput}
                      helperText={`${
                        text ? text.length : 0
                      } out of ${maxChars} characters`}
                      onChange={e => {
                        updatePersonalization(
                          activeProductId,
                          index,
                          sanitizePersonalization(e.target.value, maxChars),
                        );
                      }}
                    />
                  ))}
                  <div></div>
                </div>
              )}

              {/* SHIPPING WARNING */}
              {hasMultiShipOpts && (STH || STS) && (
                <div className={classes.shipMethod}>
                  {STH ? (
                    <HomeIcon color="primary" className={classes.shipIcon} />
                  ) : (
                    <SchoolIcon color="primary" className={classes.shipIcon} />
                  )}

                  <div className={classes.shipMethodTxt}>
                    Ship to {STH ? "home" : ship_to_school_label} only
                  </div>
                  <Tooltip
                    title={tooltipTxt}
                    placement="top"
                    classes={{ tooltipPlacementTop: classes.tooltip }}
                  >
                    <InfoIcon color="primary" className={classes.infoIcon} />
                  </Tooltip>
                </div>
              )}

              {/* DISABLED */}
              {disable && (
                <div className={classes.changeDelivery}>
                  <div>Change delivery to </div>
                  <div
                    className={classes.changeDeliveryLink}
                    onClick={() => setShowDeliverySwitch(true)}
                  >
                    Ship to {STH ? "home" : ship_to_school_label}
                  </div>
                </div>
              )}

              {/* ADD */}
              {!isSelected && !disable && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addButton}
                  onClick={handleAddRemoveProduct}
                  disabled={!Boolean(activeProduct)}
                  size="large"
                >
                  Add to cart
                </Button>
              )}

              {/* UPDATE */}
              {isSelected && (
                <div className={classes.addedActions}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={
                      quantity > 1
                        ? () => handleQtyChange(activeProductId, "decrement")
                        : handleAddRemoveProduct
                    }
                  >
                    {quantity > 1 && (
                      <DecrementIcon className={classes.addedActionIcons} />
                    )}
                    {quantity < 2 && (
                      <RemoveIcon
                        className={classes.addedActionIcons}
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                  <div className={classes.addedNum}>{quantity} added</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() =>
                      handleQtyChange(activeProductId, "increment")
                    }
                  >
                    <AddIcon className={classes.addedActionIcons} />
                  </IconButton>
                </div>
              )}

              {is_discount_card && discount_card_id && (
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.viewDiscountOffers}
                    onClick={() => setShowDiscountCard(true)}
                  >
                    View offers
                  </Button>
                  <div className={classes.discountOfferRestrictions}>
                    Restrictions apply. Offers subject to change without notice.
                  </div>
                </div>
              )}

              <div
                className={classnames(
                  classes.description,
                  "quill" /* for quill css */,
                )}
              >
                {parse(
                  `${displayDescription ? displayDescription : "<p></p>"}`,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeliverySwitch && (
        <SwitchDeliveryWarningDialog closeDialog={closeDeliverySwitchWarning} />
      )}
      {showDiscountCard && (
        <PreviewDiscountCard
          close={() => setShowDiscountCard(false)}
          id={discount_card_id}
        />
      )}

      {showUnavailableModal && (
        <ItemUnavailableWithShippingMethodModal
          onClose={() => setShowUnavailableModal(false)}
        />
      )}
    </SwipeableDrawer>
  );
};

export const ProductDetails = connect(
  state => {
    const {
      product: {
        selectedProducts,
        variantProducts,
        isShipToSchool,
        shipping_options,
      },
      campaign: { ship_to_school_label },
    } = state;
    return {
      selectedProducts,
      variantProducts,
      isShipToSchool,
      hasMultiShipOpts: shipping_options === "both",
      ship_to_school_label,
    };
  },
  dispatch => {
    return { actions: bindActionCreators(productActions, dispatch) };
  },
)(_ProductDetails);

function ThumbnailCard({ image, activeImage, setActiveImage, lastCard }) {
  const classes = productDetailStyles({
    lastCard,
    active: Boolean(image === activeImage),
  });
  return (
    <div
      className={classes.thumbnailCard}
      onClick={() => setActiveImage(image)}
    >
      <img className={classes.thumbnail} src={image} alt="thumbnail" />
    </div>
  );
}

function SwatchOptionItems({
  option_id,
  option_items,
  selectedCombo,
  availableCombos,
  setSelectedCombo,
  colorSwatch,
}) {
  const classes = productDetailStyles();

  return (
    <div className={classes.optionItems}>
      {Object.keys(option_items).map((key, index) => {
        const { item_id, value } = option_items[key];
        const available =
          _findIndex(availableCombos, {
            ...selectedCombo,
            [option_id]: item_id,
          }) > -1;
        const selected = selectedCombo[option_id] === item_id;
        return (
          <Tooltip
            key={index}
            title={!available ? "unavailable" : ""}
            placement="top"
            classes={{ tooltipPlacementTop: classes.tooltip }}
          >
            <div
              className={classnames(
                classes.swatchContainer,
                colorSwatch && selected && classes.selectedColorSwatchContainer,
                available && classes.pointer,
                !available && classes.disabledSwatch,
              )}
              onClick={
                !available
                  ? null
                  : () =>
                      setSelectedCombo({
                        ...selectedCombo,
                        [option_id]: item_id,
                      })
              }
            >
              {colorSwatch && (
                <div
                  className={classnames(
                    classes.colorSwatch,
                    selected && classes.selectedColorSwatch,
                  )}
                  style={{
                    backgroundColor: hexToRGBA(value, available ? 1 : 1),
                  }}
                >
                  {!available && <div className={classes.diagonalLine} />}
                </div>
              )}
              {!colorSwatch && (
                <div
                  className={classnames(
                    classes.textSwatch,
                    selected && classes.selectedTextSwatch,
                  )}
                >
                  <div
                    className={classnames(!available && classes.unavailableTxt)}
                  >
                    {value}
                  </div>
                  {!available && <div className={classes.diagonalLine} />}
                </div>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

function DropdownOptionItems({
  option_id,
  option_items,
  selectedCombo,
  availableCombos,
  setSelectedCombo,
}) {
  const classes = productDetailStyles();
  const [value, setValue] = useState("");

  return (
    <TextField
      select
      variant="outlined"
      size="small"
      value={value}
      label="Select an Option"
      onChange={({ target }) => {
        const { item_id } = target.value;
        setValue(target.value);
        setSelectedCombo({
          ...selectedCombo,
          [option_id]: item_id,
        });
      }}
      className={classes.select}
    >
      {Object.keys(option_items).map((key, index) => {
        const { item_id, value } = option_items[key];
        const available =
          _findIndex(availableCombos, {
            ...selectedCombo,
            [option_id]: item_id,
          }) > -1;
        return (
          <MenuItem key={index} value={option_items[key]} disabled={!available}>
            <span
              className={classnames(!available && classes.unavailableMenuItem)}
            >
              {value}
            </span>
          </MenuItem>
        );
      })}
    </TextField>
  );
}

function sanitizePersonalization(value, max) {
  if (!value) return value;
  return value
    .replace(
      /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g,
      "",
    )
    .slice(0, max);
}
