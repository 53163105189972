import React from "react";
import {
  init,
  ErrorBoundary,
  // browserTracingIntegration,
  // replayIntegration,
} from "@sentry/react";
import ReactDOM from "react-dom";
import "./styles/general.css";
import "./styles/quilljs.css";
import "./styles/react-slick.css";
import "./fonts/graphik.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { create as createJSS } from "jss";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { jssPreset, StylesProvider } from "@material-ui/styles";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { store } from "./state";
import {
  OrgHome,
  VolunteerPage,
  TeamPage,
  VolunteerRegistration,
  DonationFormIframe,
  NotFound,
  Home,
  EventBoard,
  Unsubscribe,
  Privacy,
} from "./pages";
import { CustomThemeProvider, ErrorScreen } from "./components";
import { Layout } from "./layout/Layout";
import { SiteWrapper } from "./components";

const jss = createJSS({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

init({
  dsn:
    "https://e27855be62aa861cbd018665f9993b33@o4504804121837568.ingest.us.sentry.io/4507985586356224",
  // integrations: [browserTracingIntegration(), replayIntegration()],
  beforeSend: (event, _hint) => {
    return process.env.REACT_APP_ENV !== "development" ? event : null;
  },
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: ["Non-Error promise rejection captured"],
  replaysSessionSampleRate: 0,
  // replaysOnErrorSampleRate:
  //   process.env.REACT_APP_ENV === "production" ? 0.5 : 0,
  replaysOnErrorSampleRate: 0,
  // tracesSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 0,
  tracesSampleRate: 0,
});

ReactDOM.render(
  <ErrorBoundary fallback={<ErrorScreen />}>
    <StylesProvider jss={jss}>
      <Provider store={store}>
        <CustomThemeProvider>
          <BrowserRouter>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              useEnterprise={true}
            >
              <SiteWrapper>
                <Switch>
                  <Route
                    exact
                    path="/vc/:volunteerCampaignId"
                    render={props => (
                      <Layout {...props}>
                        <VolunteerPage {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug"
                    render={props => (
                      <Layout {...props}>
                        <OrgHome {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/:orgSlug/:campaignSlug"
                    render={props => (
                      <Layout {...props}>
                        <DonationFormIframe {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug/eventboard"
                    render={props => (
                      <Layout {...props} noLayout>
                        <EventBoard {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug/teams/:teamSlug"
                    render={props => (
                      <Layout {...props}>
                        <TeamPage {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug/:volunteerSlug"
                    render={props => (
                      <Layout {...props}>
                        <VolunteerPage {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug/volunteers/register"
                    render={props => (
                      <Layout {...props}>
                        <VolunteerRegistration {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug/:volunteerSlug/volunteers/register"
                    render={props => (
                      <Layout {...props}>
                        <VolunteerRegistration {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:orgSlug/:campaignSlug/teams/:teamSlug/volunteers/register"
                    render={props => (
                      <Layout {...props}>
                        <VolunteerRegistration {...props} />
                      </Layout>
                    )}
                  />
                  <Route exact path="/" render={() => <Home />} />
                  <Route
                    exact
                    path="/privacy"
                    render={props => <Privacy {...props} />}
                  />
                  <Route exact path="/not-found" render={() => <NotFound />} />
                  <Route
                    exact
                    path="/unsubscribe"
                    render={props => <Unsubscribe {...props} />}
                  />
                  <Route path="/*" render={() => <NotFound />} />
                </Switch>
              </SiteWrapper>
            </GoogleReCaptchaProvider>
          </BrowserRouter>
        </CustomThemeProvider>
      </Provider>
    </StylesProvider>
  </ErrorBoundary>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
