import { makeStyles } from "@material-ui/core";

export const productCardStyles = makeStyles(theme => ({
  card: {
    width: 260,
    margin: "0 0 48px 40px",
    "& $moreDetails": { display: "none" },
    "&:hover $moreDetails": { display: "flex" },
    [theme.breakpoints.down("sm")]: {
      margin: "0 12px 48px 12px",
      width: 152,
      "&:hover $moreDetails": { display: "none" },
    },
  },
  imgWrapper: {
    position: "relative",
    width: 260,
    height: 260,
    lineHeight: 0,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      width: 152,
      height: 152,
      marginBottom: 12,
    },
  },
  image: {
    width: 260,
    height: 260, // TODO: maybe this should be dynamic
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: 152,
      height: 152,
      marginBottom: 12,
    },
  },
  moreDetails: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: 0.91,
    width: 120,
    height: 32,
    borderRadius: 16,
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: 0,
  },
  cardTag: {
    position: "absolute",
    top: 16,
    left: 16,
    height: 40,
    width: 159,
    borderRadius: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    [theme.breakpoints.down("sm")]: {
      top: 8,
      left: 8,
      height: 30,
      width: 107,
      fontSize: 10,
      letterSpacing: 0.13,
    },
  },
  cardTagIcon: {
    marginRight: 4,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  productName: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: 12,
    lineHeight: "19px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "15px",
    },
  },
  price: {
    fontSize: 20,
    letterSpacing: 0,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      margin: "12px 0",
    },
  },
  disabled: {
    color: "#AAAAAA",
  },
  addButtonRow: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  addButton: {
    width: 86,
    height: 38,
  },
  addedActions: {
    width: 160,
    height: 38,
    borderRadius: 19,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iconButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  addedActionIcons: {
    color: theme.palette.primary.contrastText,
  },
  addedNum: {
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
  },
  editPersonalizationWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginTop: 12,
    cursor: "pointer",
    width: "fit-content",
  },
  editPersonalization: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  editIcon: {
    marginRight: 4,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  shipMethod: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
  },
  shipMethodTxt: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.17,
    color: theme.palette.primary.main,
    paddingLeft: 6,
    paddingRight: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      paddingLeft: 2,
      letterSpacing: 0.15,
    },
  },
  shipIcon: {
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  infoIcon: {
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  changeDelivery: {
    marginTop: -4,
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    color: "#AAAAAA",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      lineHeight: "20px",
    },
  },
  changeDeliveryLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    marginLeft: 4,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  tooltip: {
    marginBottom: "4px !important",
    backgroundColor: "#b1b1b1",
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "15px",
    color: "#FFFFFF",
    padding: 8,
  },
  variantsInCart: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    width: "fit-content",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: 12,
    },
  },
  checkIcon: {
    marginLeft: 16,
    marginRight: 4,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  inCart: {
    textDecoration: "underline",
  },
}));
