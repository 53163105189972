import { AFFILIATES } from "../../lib";

function getUrls(state) {
  const query = window.location.search;
  const {
    slug: orgSlug = "",
    campaignSlug = "",
    volunteerSlug = "",
    team_slug: teamSlug = "",
    campaignId = "",
    company_frontend_url = "",
    orgId = "",
  } = state.campaign;
  const baseUrl = `/${orgSlug}/${campaignSlug}`;

  let homeUrl = baseUrl;
  if (volunteerSlug) homeUrl = `${baseUrl}/${volunteerSlug}`;
  else if (teamSlug) homeUrl = `${baseUrl}/teams/${teamSlug}`;

  const loginUrl = `${process.env.REACT_APP_ADMIN_URL}/login?c=${campaignId}&org_id=${orgId}`;

  return {
    homeUrl: `${homeUrl}${query}`,
    fullHomeUrl: `${company_frontend_url}${homeUrl}${query}`,
    fullHomeUrlWithAffiliate: `${company_frontend_url}${homeUrl}?a=${AFFILIATES.WEBSITE_SHARING_LINKS}`,
    orgUrl: `${baseUrl}${query}`,
    teamUrl: `${baseUrl}/teams/${teamSlug}${query}`,
    volunteerUrl: `${baseUrl}/${volunteerSlug}${query}`,
    registerUrl: `${homeUrl}/volunteers/register${query}`,
    loginUrl,
  };
}

export { getUrls };
