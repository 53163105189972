import { makeStyles } from "@material-ui/core";

export const headerStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: ({ notOnTop }) =>
      notOnTop ? "rgba(255, 255, 255, .85)" : "#FFFFFF",
    boxShadow: "0 8px 14px 0 rgba(216,221,230,0.5)",
    height: "80px",
    [theme.breakpoints.down("sm")]: {
      height: "56px",
    },
  },
  toolbar: {
    minHeight: "80px", // to override MUI
    maxHeight: "100%",
    height: "80px",
    padding: "0",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("md")]: {
      padding: "0 16px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "56px",
      minHeight: "56px", // to override MUI
      padding: "0 16px",
    },
  },
  navButton: {
    marginLeft: 8,
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 20,
    paddingRight: 20,
    letterSpacing: "0.2px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  donateButton: {
    marginLeft: 12,
    letterSpacing: "0.2px",
    fontSize: 16,
    width: 140,
    fontWeight: 600,
    "&:hover": {
      opacity: 0.8,
    },
  },
  cart: {
    color: theme.palette.text.primary,
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  menuIcon: {
    color: theme.palette.text.primary,
  },
  logoContainer: {
    maxHeight: 48,
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 32,
      marginLeft: ({ isProduct }) => (isProduct ? 0 : 24),
      marginRight: ({ isProduct }) => (isProduct ? 0 : 8),
    },
  },
  logo: {
    maxHeight: 48,
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 32,
      maxWidth: 155,
    },
  },
  mobileMenuAndLogo: {
    display: "flex",
    alignItems: "center",
  },
  mobileHeaderDonate: {
    height: 32,
    width: 100,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
  },

  //////////MOBILE MENU STYLES//////////
  mobileMenuContainer: {
    minHeight: "100vh",
    padding: "32px 32px 100px 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuCloseContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  mobileTopLinks: {
    marginTop: 16,
  },
  mobileLink: {
    display: "block",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "0.25px",
    color: theme.palette.text.primary,
    marginBottom: 40,
    "&:hover": { textDecoration: "none" },
  },
  mobileDonateButton: {
    letterSpacing: "0.2px",
    fontSize: 16,
    width: "100%",
    fontWeight: 600,
    "&:hover": {
      opacity: 0.8,
    },
  },
  mobileBottomButton: {
    marginTop: 24,
  },
}));
