import React from "react";
import { IconButton } from "@material-ui/core";
import WhatsappIcon from "../../images/Whatsapp.svg";
import { connect } from "react-redux";
import { getSocialMediaTxtSelector } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";

function _Whatsapp({ large, socialTxt }) {
  const classes = socialMediaStyles();

  return (
    <IconButton
      href={`https://wa.me/?text=${socialTxt}`}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={WhatsappIcon}
        alt="whatsapp"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Whatsapp = connect(state => {
  return { socialTxt: getSocialMediaTxtSelector(state, true) };
})(_Whatsapp);
