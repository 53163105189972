import { productTypes } from "./actions";

const initialState = {
  categories: {},
  categorizedProducts: {},
  digitalAddonCategories: {},
  digitalAddonCategorizedProducts: {},
  selectedProducts: {},
  isShipToSchool: false,
  emptyCart: true,
  orderItemsTotal: 0,
  orderOnlineFee: 0,
  orderCustomFee: 0,
  orderTotal: 0,
  orderItemCount: 0,
  orderHasDonationOrDonationItems: false,
  orderHasDiscountCards: false,
  productDonation: null,
  checkoutTriggered: false,
  tax: 0,
  shipping: 0,
  giftCardsValue: 0,
  loadingOrderFees: false,
  orderFeesResolved: false,
  hasOrderFeesError: false,
  noShippingCharges: false,
  rateQuotes: [],
  needsTaxFetch: false,
};

const resetCartState = {
  selectedProducts: {},
  // isShipToSchool: false, // don't reset this will break ship to school only campaigns - this gets reset with the useEffect here src/components/product/ProductDeliveryOptions.jsx, where we bind isShipToSchool to ship_to_school
  productDonation: null,
  productDonationProcessingFee: null,
  checkoutTriggered: false,
  emptyCart: true,
  orderItemsTotal: 0,
  orderOnlineFee: 0,
  orderCustomFee: 0,
  orderTotal: 0,
  orderItemCount: 0,
  orderHasDonationOrDonationItems: false,
  orderHasDiscountCards: false,
  tax: 0,
  shipping: 0,
  loadingOrderFees: false,
  orderFeesResolved: false,
  hasOrderFeesError: false,
  giftCardsValue: 0,
  noShippingCharges: false,
  rateQuotes: [],
  needsTaxFetch: false,
};

const productReducers = (state = initialState, action) => {
  switch (action.type) {
    case productTypes.CAMPAIGN_PRODUCTS_FETCH:
      return {
        ...state,
        ...action.data,
      };
    case productTypes.ADD_PRODUCT:
      const addedProduct = action.data.product;
      return {
        ...state,
        orderFeesResolved: false,
        selectedProducts: {
          ...state.selectedProducts,
          [action.data.id]: {
            qty: 1,
            product: addedProduct,
            personalization: addedProduct.can_personalize ? [""] : null,
          },
        },
      };
    case productTypes.REMOVE_PRODUCT:
      const { ["" + action.id]: _remove, ...rest } = state.selectedProducts;
      return {
        ...state,
        orderFeesResolved: false,
        selectedProducts: { ...rest },
      };
    case productTypes.CHANGE_QUANTITY:
      const qtyChangeId = action.data.id;
      const qtyChangeProduct = state.selectedProducts[qtyChangeId];
      const isIncrement = action.data.action === "increment";
      let currQty = qtyChangeProduct.qty;
      const updatedQty = isIncrement ? ++currQty : --currQty;
      let personalization = null;
      if (qtyChangeProduct.personalization) {
        if (isIncrement) {
          personalization = [...qtyChangeProduct.personalization, ""];
        } else {
          qtyChangeProduct.personalization.pop();
          personalization = [...qtyChangeProduct.personalization];
        }
      }
      return {
        ...state,
        orderFeesResolved: false,
        selectedProducts: {
          ...state.selectedProducts,
          [qtyChangeId]: {
            ...qtyChangeProduct,
            qty: updatedQty,
            personalization,
          },
        },
      };
    case productTypes.UPDATE_PERSONALIZATION:
      const UPId = action.data.id;
      const UPData = state.selectedProducts[UPId];
      return {
        ...state,
        selectedProducts: {
          ...state.selectedProducts,
          [UPId]: {
            ...UPData,
            personalization: Object.assign([], UPData.personalization, {
              [action.data.index]: action.data.value,
            }),
          },
        },
      };
    case productTypes.IS_SHIP_TO_SCHOOL_SET:
      return {
        ...state,
        isShipToSchool: action.isShipToSchool,
      };
    case productTypes.PRODUCT_DONATION_SET:
      return {
        ...state,
        orderFeesResolved: false,
        productDonation: action.productDonation,
      };
    case productTypes.ORDER_FEES_SET:
      return {
        ...state,
        ...action.data,
        loadingOrderFees: false,
        orderFeesResolved: true,
      };
    case productTypes.ORDER_FEES_LOADING:
      return {
        ...state,
        loadingOrderFees: true,
        hasOrderFeesError: false,
        orderFeesResolved: false,
        noShippingCharges: false,
        rateQuotes: [],
        needsTaxFetch: false,
      };
    case productTypes.ORDER_FEES_ERROR:
      return {
        ...state,
        loadingOrderFees: false,
        hasOrderFeesError: true,
        orderFeesResolved: false,
      };
    case productTypes.ORDER_FEES_RESOLVED_TOGGLE:
      return { ...state, orderFeesResolved: action.orderFeesResolved };
    case productTypes.CHECKOUT_TRIGGERED_SET:
      return { ...state, checkoutTriggered: true };
    case productTypes.CART_RESET:
      return { ...state, ...resetCartState };
    case productTypes.NO_ORDER_FEES_SET:
      return {
        ...state,
        tax: 0,
        shipping: 0,
        loadingOrderFees: false,
        hasOrderFeesError: false,
        orderFeesResolved: true,
        noShippingCharges: true,
        needsTaxFetch: false,
      };
    case productTypes.SHIPPING_SET:
      return { ...state, shipping: action.shipping };
    case productTypes.ORDER_FIGURES_UPDATE:
      return { ...state, ...action.orderFigures };
    default:
      return state;
  }
};

export { productReducers };
