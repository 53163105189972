import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { participantContainerStyles } from "./ParticipantContainer.styles";

export function _MemberList(props) {
  const {
    type,
    teams,
    volunteers,
    orgSlug,
    campaignSlug,
    showVolunteerItemProgress,
  } = props;
  const parentClasses = participantContainerStyles();
  const classes = styles();
  const history = useHistory();
  const query = window.location.search;
  const isTeams = type === "teams";
  const activeList = isTeams ? teams : volunteers;
  const itemBased = Boolean(!isTeams && showVolunteerItemProgress);

  const handleClick = slug => {
    history.push(
      `/${orgSlug}/${campaignSlug}/${isTeams ? "teams/" : ""}${slug}${query}`,
    );
  };

  return (
    <Fragment>
      {activeList.map((el, index) => {
        const { name, raised, percentRaised, goal, slug } = el;
        return (
          <div
            key={index}
            className={parentClasses.card}
            onClick={() => handleClick(slug)}
          >
            <div className={classes.name}>{name}</div>

            <div className={classes.progressBar}>
              <div
                className={classes.progress}
                style={{ width: percentRaised }}
              />
            </div>

            <div className={classes.bottomRow}>
              <div className={classes.total}>
                {itemBased ? `${raised} Items sold` : `$${raised} Raised`}
              </div>
              <div className={classes.goal}>
                {`Goal: ${itemBased ? "" : "$"}${goal}`}
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}

export const MemberList = connect(state => {
  const {
    team: { teams },
    volunteer: { volunteers },
    campaign: { slug: orgSlug, campaignSlug, showVolunteerItemProgress },
  } = state;
  return {
    teams,
    volunteers,
    orgSlug,
    campaignSlug,
    showVolunteerItemProgress,
  };
})(_MemberList);

const styles = makeStyles(theme => ({
  name: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "22px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  progressBar: {
    margin: "12px 0",
    display: "flex",
    height: 16,
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: 20,
  },
  progress: {
    height: "100%",
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    alignItems: "center",
  },
  total: {
    fontSize: "20px",
    fontWeight: "bold",
    letterSpacing: "0.25px",
    whiteSpace: "nowrap",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      letterSpacing: "0.2px",
    },
  },
  goal: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
  },
}));
