const uiTypes = {
  UI_LOADING: "UI_LOADING",
  UI_LOADING_RESET: "UI_LOADING_RESET",
  UI_DATA_FETCH_ERROR: "UI_DATA_FETCH_ERROR",
  UI_TOAST_SET: "UI_TOAST_SET",
  UI_TOAST_RESET: "UI_TOAST_RESET",
  SESSION_ENDED_SET: "SESSION_ENDED_SET",
  AFFILIATE_SET: "AFFILIATE_SET",
  HASH_LINK_TRIGGER_UPDATE: "HASH_LINK_TRIGGER_UPDATE",
};

const uiActions = {
  setLoading() {
    return { type: uiTypes.UI_LOADING };
  },
  resetLoading() {
    return { type: uiTypes.UI_LOADING_RESET };
  },
  setToast(toast) {
    return { type: uiTypes.UI_TOAST_SET, toast };
  },
  resetToast() {
    return { type: uiTypes.UI_TOAST_RESET };
  },
  setAffiliate(affiliate) {
    return { type: uiTypes.AFFILIATE_SET, affiliate };
  },
  updateHashLinkTrigger() {
    return { type: uiTypes.HASH_LINK_TRIGGER_UPDATE };
  },
};

const setToast = uiActions.setToast;

export { uiTypes, uiActions, setToast };
