import React from "react";
import { IconButton } from "@material-ui/core";
import PinterestIcon from "../../images/Pinterest.svg";
import { connect } from "react-redux";
import { getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";

function _Pinterest({ large, siteUrl }) {
  const classes = socialMediaStyles();
  const link = `https://www.pinterest.com/pin/find/?url=${siteUrl}`;

  return (
    <IconButton
      href={link}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={PinterestIcon}
        alt="pinterest"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Pinterest = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return { siteUrl: fullHomeUrlWithAffiliate };
})(_Pinterest);
