import React from "react";
import { IconButton } from "@material-ui/core";
import TwitterIcon from "../../images/Twitter.svg";
import { connect } from "react-redux";
import { getSocialMediaTxtSelector, getUrls } from "../../state";
import { socialMediaStyles } from "./SocialMedia.styles";

function _Twitter({ large, siteLink, socialTxt }) {
  const classes = socialMediaStyles();
  const link = `https://twitter.com/intent/tweet?url=${siteLink}&text=${socialTxt}`;

  return (
    <IconButton
      href={link}
      target="_blank"
      className={large ? classes.largeButton : classes.button}
    >
      <img
        src={TwitterIcon}
        alt="twitter"
        className={large ? classes.largeIcon : classes.icon}
      ></img>
    </IconButton>
  );
}

export const Twitter = connect(state => {
  const { fullHomeUrlWithAffiliate } = getUrls(state);
  return {
    siteLink: fullHomeUrlWithAffiliate,
    socialTxt: getSocialMediaTxtSelector(state),
  };
})(_Twitter);
