import React, { useState, useEffect, Fragment } from "react";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";
import { useField } from "react-final-form";
import { connect } from "react-redux";
import { donationFormStyles } from "./DonationForm.styles";
import {
  calculatePercentOfNum,
  formatCurrencyDisplay,
  formatMoneyInput,
  hexToRGBA,
} from "../../lib";
const CUSTOM = "custom";
const NO_TIP = "noTip";
const customAndNoneOptions = [
  { percent: CUSTOM, isCustom: true },
  { percent: NO_TIP, isNone: true },
];
const defaultPercentOptions = [{ percent: 2 }, { percent: 3 }, { percent: 5 }];

function _Tips({ campaign, donationAmount }) {
  const {
    tip_title = "",
    tip_paragraph = "",
    tip_percent_options: TPO,
  } = campaign;

  const parentClasses = donationFormStyles();
  const classes = styles();
  const [selectedOption, setSelectedOption] = useState("");
  const [percentOptions, setPercentOptions] = useState([]);
  const [customTip, setCustomTip] = useState("");
  const tipField = useField("tip");

  useEffect(() => {
    if (Array.isArray(TPO) && TPO.length) {
      setPercentOptions([...TPO, ...customAndNoneOptions]);
      const defaultOptionIndex = TPO.findIndex(
        ({ defaultOption }) => defaultOption,
      );
      const index = defaultOptionIndex > -1 ? defaultOptionIndex : 0;
      setSelectedOption(TPO[index].percent);
    } else {
      setPercentOptions([...defaultPercentOptions, ...customAndNoneOptions]);
      setSelectedOption(defaultPercentOptions[0].percent);
    }
  }, [TPO]);

  useEffect(() => {
    switch (true) {
      case selectedOption === CUSTOM:
        tipField.input.onChange(customTip);
        break;
      case selectedOption === NO_TIP:
        tipField.input.onChange("");
        setCustomTip("");
        break;
      case Boolean(selectedOption):
        tipField.input.onChange(
          calculatePercentOfNum(donationAmount, selectedOption),
        );
        setCustomTip("");
        break;
      default:
        tipField.input.onChange("");
        setCustomTip("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationAmount, selectedOption, customTip]);

  return (
    <Fragment>
      <div className={parentClasses.formSubHeader}>{tip_title}</div>
      <div className={classes.paragraph}>{tip_paragraph}</div>
      <div className={classes.cardsWrapper}>
        <div className={classes.cards}>
          {percentOptions.map((option, index) => (
            <OptionCard
              key={index}
              donationAmount={donationAmount}
              option={option}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              customTip={customTip}
              setCustomTip={setCustomTip}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

function OptionCard({
  donationAmount,
  option,
  setSelectedOption,
  selectedOption,
  customTip,
  setCustomTip,
}) {
  const { percent, isCustom, isNone } = option;
  const selected = percent === selectedOption;
  const classes = styles({ selected });

  const amount = formatCurrencyDisplay(
    Number(calculatePercentOfNum(donationAmount, percent)),
  );

  const handleCustomTipChange = e => {
    const formattedVal = formatMoneyInput(e.target.value);
    setCustomTip(formattedVal);
  };

  return (
    <div onClick={() => setSelectedOption(percent)} className={classes.card}>
      {isNone && <div className={classes.percent}>No thank you</div>}
      {isCustom && <div className={classes.percent}>Custom amount</div>}
      {isCustom && (
        <TextField
          value={customTip}
          onChange={handleCustomTipChange}
          type="number"
          className={classes.input}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      )}
      {!isNone && !isCustom && (
        <Fragment>
          <div className={classes.percent}>{`${percent}%`}</div>
          <div className={classes.amount}>{`$${amount}`}</div>
        </Fragment>
      )}
    </div>
  );
}

export const Tips = connect(state => {
  const {
    campaign,
    donation: { donationAmount },
  } = state;
  return { campaign, donationAmount };
})(_Tips);

const styles = makeStyles(theme => ({
  paragraph: {
    marginTop: 18,
    marginBottom: 16,
    color: hexToRGBA(theme.palette.text.primary, 0.87),
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "16px",
  },
  cardsWrapper: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
    },
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    width: "calc(100% + 24px)",
    marginRight: -24,
    [theme.breakpoints.only("xs")]: {
      width: 330,
      maxWidth: "100%",
      marginRight: 0,
      justifyContent: "space-between",
    },
    [theme.breakpoints.only("sm")]: {
      maxWidth: "calc(100vw - 32px)",
    },
  },
  card: {
    height: 100,
    width: 156,
    border: ({ selected }) =>
      selected
        ? `3px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: ({ selected }) =>
      selected ? theme.palette.primary.main : theme.palette.text.secondary,
    marginRight: 24,
    marginBottom: 24,
    padding: "12px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.09),
    },
    [theme.breakpoints.only("xs")]: {
      width: 156,
      maxWidth: "48%",
      marginRight: 0,
      padding: "12px 14px",
      marginBottom: 14,
    },
  },
  percent: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  amount: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  input: {
    width: "100%",
    marginTop: 12,
    [`& fieldset`]: {
      borderRadius: 19,
    },
  },
}));
